// This icon file is generated automatically.

import { IconDefinition } from '../types';

const MobileDollarFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M9.17 3.44V1.12C9.17.5 8.67 0 8.04 0h-.99l-.72 1.24H2.84L2.12 0h-.99C.51 0 0 .5 0 1.12v13.76C0 15.5.5 16 1.13 16h6.91c.62 0 1.13-.5 1.13-1.12v-2.32A4.58 4.58 0 0 1 4.57 8a4.58 4.58 0 0 1 4.6-4.56ZM4.92 14.1c.23 0 .42.19.42.42 0 .23-.19.42-.42.42h-.67a.42.42 0 0 1-.42-.42c0-.23.19-.42.42-.42h.67m8.95-8.45a.42.42 0 0 1-.42-.42c0-.8-.66-1.45-1.47-1.45a.42.42 0 0 1-.42-.42c0-.23.19-.42.42-.42a2.3 2.3 0 0 1 2.31 2.3c0 .22-.18.4-.42.4m1.71 0c.23 0 .42-.19.42-.42a4 4 0 0 0-4.02-3.98.42.42 0 0 0-.42.42c0 .23.19.42.42.42a3.16 3.16 0 0 1 3.18 3.14c0 .23.19.42.42.42m-6.4-1.37A3.75 3.75 0 0 0 5.4 8a3.75 3.75 0 0 0 3.76 3.73A3.75 3.75 0 0 0 12.94 8a3.75 3.75 0 0 0-3.77-3.73m1.69 5.04c-.16.52-.7.8-1.28.87v.47c0 .23-.18.42-.42.42a.42.42 0 0 1-.42-.42v-.49c-.68-.13-1.18-.53-1.31-1.08a.42.42 0 0 1 .82-.2c.07.33.54.48.94.5.46 0 .81-.15.86-.31.03-.11.01-.17 0-.2-.04-.07-.18-.23-.67-.37l-.31-.07A2.9 2.9 0 0 1 7.98 8c-.3-.22-.47-.53-.5-.86-.02-.32.1-.63.35-.87.23-.25.57-.39.92-.45v-.48c0-.23.19-.42.42-.42.24 0 .42.19.42.42v.48l.24.05c.57.16.96.51 1.07.98a.42.42 0 0 1-.32.5.42.42 0 0 1-.5-.32c-.05-.22-.35-.32-.47-.36-.43-.12-.97-.04-1.18.18a.32.32 0 0 0-.1.23c0 .12.1.2.16.26.17.12.5.2.78.27l.35.08c.58.17.98.42 1.17.76.14.25.17.55.08.85M6.08 0l-.34.57H3.43L3.09 0h2.99',
        },
      },
    ],
  },
  name: 'mobile-dollar',
  theme: 'filled',
};

export default MobileDollarFilled;
