// This icon file is generated automatically.

import { IconDefinition } from '../types';

const DoublePaperFilled: IconDefinition = {
  icon: {
    tag: 'svg',
    attrs: {
      fill: 'currentColor',
      viewBox: '0 0 16 16',
      xmlns: 'http://www.w3.org/2000/svg',
      focusable: 'false',
    },
    children: [
      {
        tag: 'path',
        attrs: {
          d: 'M7.33 13h3.8a2.65 2.65 0 0 1-2.46 1.67H4A2.67 2.67 0 0 1 1.33 12V6.67a2.67 2.67 0 0 1 2.34-2.65v5.31A3.67 3.67 0 0 0 7.33 13M13 4.17h1.41a1.1 1.1 0 0 0-.14-.17L12 1.73a.77.77 0 0 0-.17-.14V3A1.17 1.17 0 0 0 13 4.17m0 1A2.17 2.17 0 0 1 10.83 3V1.33h-3.5A2.67 2.67 0 0 0 4.67 4v5.33A2.67 2.67 0 0 0 7.33 12H12a2.67 2.67 0 0 0 2.67-2.67V5.17H13',
        },
      },
    ],
  },
  name: 'double-paper',
  theme: 'filled',
};

export default DoublePaperFilled;
