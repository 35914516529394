// GENERATE BY ./scripts/generate.ts
// DON NOT EDIT IT MANUALLY

import * as React from 'react';
import { CryptoFilled as CryptoFilledSvg } from 'icons-svg';
import type { IconReactProps } from '../components/IconReact';
import IconReact from '../components/IconReact';

const CryptoFilled = (
  props: IconReactProps,
  ref: React.Ref<HTMLSpanElement>,
): React.ReactElement => (
  <IconReact {...props} ref={ref} icon={CryptoFilledSvg} />
);

CryptoFilled.displayName = 'CryptoFilled';
export default React.forwardRef<HTMLSpanElement, IconReactProps>(CryptoFilled);
